<template>
  <div class="template-10">
    <div class="content-box" :style="{backgroundColor:announcement[0]?announcement[0].boxBack:'#ddd'}">
      <div class="data-show">
        <img src="/assets/img/announcement.png">
        <marquee v-if="announcement[0]" style="width: 100%">
          <span v-html="announcement[0].content" :style="{color: announcement[0].textColor}"></span>
        </marquee>
        <span v-else style="color: #868686">请填写公告内容，公告将会在手机上滚动展示</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "template-10",
  props: {
    showData: {
      type: Object
    }
  },
  data() {
    return {
      announcement: {}
    }
  },
  created() {
    if(this.showData.blockList[0]) this.announcement = this.showData.blockList[0].block_value
  }
}
</script>

<style lang="scss" scoped>
.template-10 {
  .content-box {
    height: 30px;
    padding: 5px 20px;
    border-radius: 6px;
    overflow: hidden;

    .data-show {
      width: 100%;
      height: 20px;
      display: flex;
      align-items: center;

      img {
        height: 20px;
        margin-right: 5px;
      }

      span {
        white-space: nowrap;
      }
    }
  }
}
</style>
