import request from '@/utils/request';
import {api} from '@/../ui-domain'

export type ClientType = 'WAP' | 'MOBILE';
export type PageType = 'INDEX' | 'CHILD_INDEX' | 'SUBJECT_INDEX';

/**
 * 获取主楼层配置
 */
export function getFloorConfig(shop_id, type, card_id = null) {
  const card = card_id === null ? '' : `&card_id=${card_id}`
  return request({
    url: `/seller/pages/pageDataTh?shop_id=${shop_id}&floor_page_type=${type}` + card,
    method: 'get'
  });
}

/**
 * 发布主楼层配置
 */
export function publishFloorConfig(
  params: {
    clientType: ClientType;
    pageType: PageType;
    page_name: string;
    page_data: string;
    company_img: string;
    floor_background: string;
    ids: number[];
  }
) {
  return request({
    url: `seller/pages/${params.clientType}/${params.pageType}`,
    method: 'put',
    data: {
      page_name: params.page_name,
      page_data: params.page_data,
      company_img: params.company_img,
      floor_background: params.floor_background,
      ids: params.ids,
    }
  });
}

/**
 * 获取子楼层配置
 */
export function getChildFloorConfig(id: number) {
  return request({
    url: `seller/pages/site-navigations/${id}`,
    method: 'get'
  })
}

/**
 * 发布子楼层配置
 */
export function publishChildFloorConfig(
  params: {
    id: number;
    page_data: string;
    ids: number[];
    floor_background: string;
  }
) {
  return request({
    url: `seller/pages/site-navigations/editPage/${params.id}`,
    method: 'put',
    data: {
      floor_background: params.floor_background,
      page_data: params.page_data,
      ids: params.ids,
    }
  })
}

/**
 * 获取子楼层配置
 */
export function getPriceUnit() {
  return request({
    url: `seller/shops`,
    method: 'get'
  })
}

/**
 * 获取图片库类型
 */
export function getAlbumTypes() {
  return request({
    url: `${api.base}/base/picture/listCategory`,
    method: 'get',
    params: {
      page_no: 1,
      page_size: 2000
    },
  })
}

/**
 * 查询图片库图片列表
 */
export function listAlbumPicture(params: { page_no: number; page_size: number; category_id: number, sn: string; }) {
  return request({
    url: `${api.base}/base/picture/listPicture`,
    method: 'get',
    params,
  });
}

/**
 * 获取礼包分类
 */
export function getGoodsCategories(depth = 1) {
  return request({
    url: `seller/goods/category/depth/${depth}`,
    method: 'get',
    loading: false,
  })
}

/**
 * 保存富文本内容
 */
export function saveCkeditor(data) {
  return request({
    url: '/seller/pages/addPageText',
    method: 'post',
    headers: {'Content-Type': 'application/json'},
    data,
  })
}

/**
 * 获取富文本内容
 */
export function getCkeditor(
  params: {
    shop_id: number;
    ext_ids: string;
}) {
  return request({
    url: `/seller/pages/getPageText`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取子楼层列表
 */
export function listChildFloors(
  params: {
    page_no: number;
    page_size: number;
    client_type: ClientType;
  }
) {
  return request({
    url: 'seller/pages/site-navigations',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 查询专题
 */
export function listGoodsSpecialSubject(
  data: {
    page_no: number;
    page_size: number;
    navigation_name?: string;
  }
) {
  return request({
    url: 'seller/pages/siteGoodsNavigationShop/page',
    method: 'post',
    loading: true,
    data,
  })
}

/**
 * 查询专题详情
 */
export function getGoodsSpecialSubject(id: number) {
  return request({
    url: `seller/pages/siteGoodsNavigationShop/${id}`,
    method: 'get',
    loading: true
  })
}

/**
 * 发布礼包专题
 */
export function publishSGoodsSpecialSubject(
  data: {
    navigation_id: number;
    count: number;
    page_data: string;
    ids: number[];
  }
) {
  return request({
    url: 'seller/pages/siteGoodsNavigationShop/edit',
    method: 'post',
    loading: true,
    data
  })
}

export function detectGoodsStatus(ids: number[]) {
  return request({
    url: '/seller/shopGoods/checkEnable',
    method: 'put',
    data: [...ids],
    headers: { "Content-Type": "application/json" },
  });
}

/**
 * 根据礼包id获取礼包信息
 */
export function getGoodsInfo(params: {
  shop_id: number,
  good_ids: string
}) {
  return request({
    url: '/seller/pages/getGoodIdsByPrice',
    method: 'get',
    params
  })
}
