
import {Component, Vue} from 'vue-property-decorator';
import {$floor} from "@/custom-floor/renovation/services/floor";

  @Component({
    name: 'set-background-color'
  })
export default class SetBackgroundColor extends Vue {
    backgroundColor:any = '#FFFFFF'

    /**
     * 颜色保存与还原处理
     * @param type 处理类型
     */
    handleColor(type) {
      if (type === 'save') {
        if (this.$route.path.split('/')[2] === 'child') {
          $floor.setBackground.next({type: 'setChildBack', data: this.backgroundColor})
          sessionStorage.setItem("reeditChildBackground", this.backgroundColor);
        } else {
          $floor.setBackground.next({type: 'setBack', data: this.backgroundColor})
          sessionStorage.setItem("reeditBackground", this.backgroundColor);
        }
      } else {
        if (this.$route.path.split('/')[2] === 'child') this.backgroundColor = sessionStorage.getItem("reeditChildBackground")
        else this.backgroundColor = sessionStorage.getItem("reeditBackground")
      }
    }

    RGBToHex(color) {
      // 十六进制颜色值的正则表达式
      const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
      // 如果是rgb颜色表示
      if (/^(rgb|RGB)/.test(color)) {
        const aColor = color.replace(/(?:\(|\)|rgb|RGB)*/g, "").split(",");
        let strHex = "#";
        for (let i = 0; i < aColor.length; i++) {
          let hex = Number(aColor[i]).toString(16);
          if (hex.length < 2) {
            hex = '0' + hex;
          }
          strHex += hex;
        }
        if (strHex.length !== 7) {
          strHex = color;
        }
        this.backgroundColor = strHex
        return
      } else if (reg.test(color)) {
        const aNum = color.replace(/#/, "").split("");
        if (aNum.length === 6) {
          return color;
        } else if (aNum.length === 3) {
          let numHex = "#";
          for (let i = 0; i < aNum.length; i += 1) {
            numHex += (aNum[i] + aNum[i]);
          }
          this.backgroundColor = numHex
          return
        }
      }
      this.backgroundColor = color
    }

    mounted() {
      if (this.$route.path.split('/')[2] === 'child') this.backgroundColor = sessionStorage.getItem("reeditChildBackground");
      else this.backgroundColor = sessionStorage.getItem("reeditBackground")
    }
}
