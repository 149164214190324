
import {Component, Mixins, PropSync, Watch} from 'vue-property-decorator';
import {TemplateExtra} from '../module';
import Placeholder from './placeholder.vue';
import {Floor} from "@/custom-floor/renovation/services/floor";
import GoodsCard from '../$components/goods-card/goods-card.vue';
import { getGoodsInfo, getPriceUnit } from '../../api';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {SwiperProxy} from '../../services/swiper';

  @Component({
    name: 'template-9',
    components: {
      Placeholder,
      GoodsCard,
      Swiper,
      SwiperSlide
    }
  })
export default class Template extends Mixins(TemplateExtra) {
    @PropSync('floor') realFloor!: Floor;
    elevatorData = {
      titleBack: '#fff',
      arrangementMode: null,
      subSwitch: false,
      floorData: [{goodsList: []}]
    };

    selectIndex = 0;
    elevatorDataShow:any = {}
    swiperProxy: SwiperProxy = null!;
    priceUnit = ''
    unitSuffix = false
    shopInfo2021:any = localStorage.getItem('shopInfo2021')

    @Watch('realFloor', {immediate: true})
    watchFloor(newSrc) {
      if (newSrc) {
        this.realFloor.elevatorSelectFloor.subscribe(value => {
          if (value < this.elevatorData.floorData.length) {
            this.selectIndex = value
            this.loadHandle('init')
          }
        })
      }
    }

    loadHandle(type = 'auto') {
      const index = this.selectIndex
      const data = JSON.parse(JSON.stringify(this.elevatorData.floorData[index].goodsList))
      // console.log(data, "template9")
      if (type === 'init' && data.length > 0) {
        if (data.length >= 20) {
          this.elevatorDataShow[index] = data.splice(0, 20)
        } else if (data.length < 20) {
          this.elevatorDataShow[index] = data
        }
        const shop_id = JSON.parse(this.shopInfo2021).shop_id
        const goodsArr = JSON.parse(JSON.stringify(this.elevatorDataShow[index]))
        const ids = goodsArr.map(g => { return g.originalData.goods_id }).join(',')
        // if (!ids) return
        console.log(ids)
        // console.log("getGoodsInfo")
        getGoodsInfo({
          shop_id,
          good_ids: goodsArr.map(g => { return g.originalData.goods_id }).join(',')
        }).then(res => {
          const arr:any = []
          goodsArr.forEach(item => {
            console.log(res, "res")
            res.forEach((item1) => {
              if (item.originalData.goods_id === item1.goods_id) {
                arr.push({ ...item, originalData: { ...item.originalData, ...item1 } })
              }
            })
          })
          this.elevatorDataShow[index] = JSON.parse(JSON.stringify(arr))
          console.log(this.elevatorDataShow[index], "this.elevatorDataShow[index]")
          this.$forceUpdate()
        })
      } else if (type === 'auto' && data.length > 0) {
        if (this.elevatorData.arrangementMode !== '0') return
        const num = data.length - this.elevatorDataShow[index].length
        const length = this.elevatorDataShow[index].length
        const goodsArr = num >= 10 ? JSON.parse(JSON.stringify(data.splice(length, 10))) : JSON.parse(JSON.stringify(data.splice(length, num)))
        const shop_id = JSON.parse(this.shopInfo2021).shop_id
        if (num <= 0) return
        const ids = goodsArr.map(g => { return g.originalData.goods_id }).join(',')
        console.log(ids)
        getGoodsInfo({
          shop_id,
          good_ids: ids
        }).then(res => {
          const arr:any = []
          goodsArr.forEach(item => {
            console.log(res, "res")
            res.forEach((item1) => {
              if (item.originalData.goods_id === item1.goods_id) {
                arr.push({ ...item, originalData: { ...item.originalData, ...item1 } })
              }
            })
          })
          this.elevatorDataShow[index] = [...this.elevatorDataShow[index], ...arr]
          // console.log(this.elevatorDataShow[index], "elevatorDataShow")
          this.$forceUpdate()
        })
      }
      console.log(this.elevatorDataShow[this.selectIndex], "elevatorDataShow[selectIndex]")
    }

    titleClick(index) {
      if (index === this.selectIndex) return
      this.selectIndex = index
      this.realFloor.elevatorSelectFloor.next(index)
      this.loadHandle('init')
    }

    init() {
      this.swiperProxy = SwiperProxy.create({
        instance: this,
        key: this.data.key,
        refKey: 'swiper',
        extras: {
          slidesPerView: 'auto',
          spaceBetween: 0,
        }
      });

      this.data.onChange.subscribe(block => {
        const data = block.list || this.data.blockList || [];
        if (data[0]?.block_value[0]) this.elevatorData = JSON.parse(JSON.stringify(data[0].block_value[0]))
        else this.elevatorData = {titleBack: '#fff', arrangementMode: null, subSwitch: false, floorData: [{goodsList: []}]}
        this.loadHandle('init')
      });

      getPriceUnit().then(res => {
        this.priceUnit = res.monetary_unit || '¥'
        if (res.monetary_unit) this.unitSuffix = true;
        else this.unitSuffix = false
      })
    }
}
