
import {Component, PropSync, Vue} from 'vue-property-decorator';
import draggable from 'vuedraggable';
import GoodsCard from '../goods-card/goods-card.vue';
import {$goods, GoodsProxy} from '@/custom-floor/renovation/services/goods';
import FloorEditorFrame from '../floor-editor-frame/floor-editor-frame.vue';

  @Component({
    name: 'goods-editor',
    components: {
      FloorEditorFrame,
      GoodsCard,
      draggable,
    }
  })
export default class GoodsEditor extends Vue {
    @PropSync('goodsList', {
      required: true,
      default: () => []
    }) realGoodsList!: GoodsProxy[];

    @PropSync('sbGoods', {default: false}) realSbGoods!: boolean;
    sorts = [
      {
        icon: 'el-icon-sort-up',
        label: '升序',
        command: 'up',
      },
      {
        icon: 'el-icon-sort-down',
        label: '降序',
        command: 'down',
      },
      {
        icon: 'el-icon-sort',
        label: '无序',
        command: '',
        hidden: true,
      },
    ];

    currentSort = this.sorts[2];

    mounted() {
      this.realGoodsList = JSON.parse(JSON.stringify(this.realGoodsList))
      console.log(this.realGoodsList, 777)
    }

    getGoods(data) {
      this.realGoodsList = this.realGoodsList.map(item => {
        if (item.id === data.id) {
          return data
        } else {
          return item
        }
      })
      console.log(this.realGoodsList, "getGoods", data.id)
    }

    selectSort(sort = this.sorts[2]) {
      this.currentSort = sort;

      let sortFn: (g1: GoodsProxy, g2: GoodsProxy) => number = null!;

      if (sort.command === 'up') {
        sortFn = (g1, g2) => g1.marketPrice - g2.marketPrice;
      } else if (sort.command === 'down') {
        sortFn = (g1, g2) => g2.marketPrice - g1.marketPrice;
      }

      if (sortFn) {
        this.realGoodsList = this.realGoodsList.sort(sortFn);
        this.realGoodsList = this.realGoodsList.sort(sortFn);
      }

      this.$emit('sync');
    }

    selectGoods() {
      console.log(this.realGoodsList)
      $goods.selectGoods(true, [...this.realGoodsList].reverse()).then(resp => {
        console.log(resp)
        this.realGoodsList = resp.reverse();
        this.realGoodsList = resp.reverse();
        this.$emit('sync');
      });
    }

    moveGoodsToTop(index = 0, disabled = false) {
      if (disabled) return;

      const goods = this.realGoodsList[index];
      const list = this.realGoodsList.filter((_, i) => i !== index);
      list.unshift(goods);
      this.realGoodsList = list;
      this.$emit('sync');
    }

    moveGoodsToPrev(index = 0, disabled = false) {
      if (disabled) return;
      const goods = this.realGoodsList[index];
      const list = this.realGoodsList.filter((_, i) => i !== index);
      list.splice(index - 1, 0, goods);
      this.realGoodsList = list;

      this.$emit('sync');
    }

    removeGoods(index) {
      this.realGoodsList.splice(index, 1);
      this.$emit('sync');
    }

    moveGoodsToNext(index = 0, disabled = false) {
      if (disabled) return;

      const goods = this.realGoodsList[index];
      const list = this.realGoodsList.filter((_, i) => i !== index);
      list.splice(index + 1, 0, goods);
      this.realGoodsList = list;

      this.$emit('sync');
    }

    moveGoodsToBottom(index = 0, disabled = false) {
      if (disabled) return;

      const goods = this.realGoodsList[index];
      const list = this.realGoodsList.filter((_, i) => i !== index);
      list.push(goods);
      this.realGoodsList = list;

      this.$emit('sync');
    }
}
