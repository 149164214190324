import {FloorItem, FloorItemProxy} from '@/custom-floor/renovation/services/floor';
import {FloorMenuItem} from '@/custom-floor/renovation/services/floor-menu-item';

export class FloorItem2Proxy extends FloorItemProxy {
  items!: FloorMenuItem[];

  constructor(item: FloorItem) {
    super(item);
    // 注册检测是否改变的函数
    item.isChange = () => this.isChange();
  }

  parse(item: FloorItem) {
    const items: any[] = item.blockList || [];
    this.items = Array.from({length: 5}).map((_, index) => {
      return FloorMenuItem.createImage(items[index]);
    });
  }

  hasError() {
    return this.items.some(b => b.hasError());
  }

  toJson() {
    return this.items.map(item => item.toImageJson());
  }

  appendItem() {
    this.items.push(FloorMenuItem.createImage(null));
  }

  removeItem(target: FloorMenuItem) {
    this.items = this.items.filter(item => item.key !== target.key);
  }
}
