
import { Component, PropSync, Vue } from 'vue-property-decorator';
import { Subject } from 'rxjs';

@Component({
  name: 'floor-editor-frame',
})
export default class FloorEditorFrame extends Vue {
  @PropSync('title', { default: '' }) realTitle!: string;
  @PropSync('subtitle', { default: '' }) realSubtitle!: string;
  @PropSync('disableAction', { default: false }) realDisableAction!: boolean;
  @PropSync('saveSilence', { default: false }) realSaveSilence!: boolean;
  @PropSync('onSave', { default: null }) realOnSave!: Subject<any>;
  @PropSync('onReset', { default: null }) realOnReset!: Subject<any>;
  @PropSync('onRemove', { default: null }) realOnRemove!: Subject<any>;
  @PropSync('isDelete', { default: false }) realIsDelete;
  @PropSync('tip', { default: null }) realIsTip;

  mounted() {
    if (this.realIsTip === 4) this.save({})
  }

  save(event) {
    this.$emit('sortSave')
    this.realOnSave.next({
      event,
      silence: this.realSaveSilence,
    });
  }

  reset(e) {
    this.$confirm('确定将此模块还原吗？', '注意', {
      confirmButtonClass: 'danger-confirm'
    }).then(() => {
      this.realOnReset.next(e);
    }, () => {
      // 不做处理
    });
  }

  remove(e) {
    this.$confirm('确定删除该模块吗？', '注意', {
      confirmButtonClass: 'danger-confirm'
    }).then(() => {
      this.realOnRemove.next(e);
    }, () => {
      // 不做处理
    });
  }
}
