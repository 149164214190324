import {FloorItem, FloorItemProxy} from '@/custom-floor/renovation/services/floor';

export class FloorItem12Proxy extends FloorItemProxy {
  rubikCube;

  constructor(item: FloorItem) {
    super(item);
    // 注册检测是否改变的函数
    item.isChange = () => this.isChange();
  }

  parse(item: FloorItem) {
    const block = item.blockList[0];
    if (block) {
      this.rubikCube = JSON.parse(JSON.stringify(block.block_value))
    } else {
      this.rubikCube = [{
        row: 2,
        column: 6,
        gapValue: 0,
        dataList: []
      }];
    }
  }

  hasError() {
    const data = this.rubikCube[0].dataList
    if (data[0]) {
      let status = false
      data.forEach(value => {
        if (value.link.type !== "NONE" && !value.link.value) status = true
      })
      return status;
    } else return true;
  }

  toJson() {
    return [
      {
        block_type: 'RUBIKCUBE',
        block_value: this.rubikCube,
      }
    ];
  }
}
