
// @ts-nocheck
import {Component, Mixins, Watch} from 'vue-property-decorator';
import {EditorExtra} from '../module';
import config from './$config';
import {FloorItem10Proxy} from './$proxy';
import FloorEditorFrame from '../$components/floor-editor-frame/floor-editor-frame.vue';

@Component({
  name: 'editor-10',
  components: {
    FloorEditorFrame,
  }
})
export default class Editor extends Mixins(EditorExtra) {
  config = config;
  proxy: FloorItem10Proxy = null!;
  inputRules = {
    content: [
      { required: true, message: '公告内容不能为空'},
      { max: 99, message: '最大长度不能超过 100 个字符'}
    ]
  }

  @Watch('proxy.announcement', {deep: true})
  watchAnnouncement(newValue) {
    if (newValue[0].content.length > 100) {
      newValue[0].content = newValue[0].content.substring(0, 100)
    }
  }

  init(): any {
    this.proxy = new FloorItem10Proxy(this.realItem);
    if (this.proxy.announcement) {
      const data = this.proxy.announcement[0].content.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll('&amp;', '&')
      this.proxy.announcement[0].content = data
      this.proxy.onSave.next({silence: true})
    }
  }
}
