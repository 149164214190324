
import {Component, PropSync, Vue, Watch} from 'vue-property-decorator';
import FloorModulePanel from './components/floor-module-panel/floor-module-panel.vue';
import MobilePreview from './components/mobile-preview/mobile-preview.vue';
import FloorModuleEditor from './components/floor-module-editor/floor-module-editor.vue';
import Album from './components/album/album.vue';
import AlbumPreview from './components/album/album-preview.vue';
import GoodsSelectorOld from './components/goods-selector-old/goods-selector-old.vue';
import {$floor, Floor} from './services/floor';
import {$scroller, Scroller} from '@/custom-floor/renovation/services/scroll';

  @Component({
    name: 'floor-renovation',
    components: {
      GoodsSelectorOld,
      FloorModuleEditor,
      MobilePreview,
      FloorModulePanel,
      Album,
      AlbumPreview,
    },
  })
export default class FloorRenovation extends Vue {
    floor: Floor = null!;
    scroller: Scroller = null!;
    disableEdit = false;
    disableAction = false;

    @Watch('$route', {
      immediate: true,
      deep: true
    })
    loadFloor() {
      const cardId:any = this.$route.params.id === undefined ? null : this.$route.params.id
      sessionStorage.setItem('subjectPage', 'false')
      const info:any = localStorage.getItem('shopInfo2021')
      $floor.get(JSON.parse(info).shop_id, 2, cardId).then(floor => {
        this.$emit('pageId', floor.id)
        this.floor = floor;
        this.$emit("customFloor", floor)
        sessionStorage.setItem("initBackground", floor.floor_background || '#FFFFFF');
        sessionStorage.setItem("reeditBackground", floor.floor_background || '#FFFFFF');
      });
    }

    mounted() {
      this.scroller = $scroller.create(this.$el.parentElement!.parentElement!);
      $floor.scrollTo = (y) => this.scroller.toTop(y);
    }

    destroyed() {
      this.destroyFloor();
    }

    destroyFloor() {
      this.floor.destroy();
      this.floor = null!;
      $floor.itemSelected.next(null!);
    }
}
