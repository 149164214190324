import ClipboardJS from 'clipboard';

export class CopyService {
  copy(text: string) {
    return new Promise((resolve, reject) => {
      const btn = document.createElement('button');
      const clipboard: any = new ClipboardJS(btn, {
        text: () => text,
      } as any);
      clipboard.on('success', resolve);
      clipboard.on('error', reject);
      btn.click();
    })
  }
}

export const $copy = new CopyService();
