<template>
  <div class="template-7">
    <img v-if="showData.blockList[0] && showData.blockList[0].block_value" :src="showData.blockList[0].block_value">
    <div v-else></div>
  </div>
</template>

<script>
export default {
  name: "template-7",
  props: {
    showData: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.template-7 {
  img {
    width: 100%;
    border-radius: 6px;
  }

  > div {
    height: 80px;
    background-color: #dddddd;
    border-radius: 8px;
  }
}
</style>
