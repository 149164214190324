
import {Component, Vue} from 'vue-property-decorator';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {GoodsProxy} from '@/custom-floor/renovation/services/goods';
import {SwiperProxy} from '@/custom-floor/renovation/services/swiper';
import GoodsCard from '../$components/goods-card/goods-card.vue';

@Component({
  name: 'placeholder',
  components: {
    Swiper,
    SwiperSlide,
    GoodsCard,
  },
})
export default class Placeholder extends Vue {
  goodsList: GoodsProxy[] = new Array(6).fill(0).map(() => new GoodsProxy(null));
  swiperProxy: SwiperProxy = null!;

  created() {
    this.swiperProxy = SwiperProxy.create({
      instance: this,
      key: undefined,
      refKey: 'swiper',
      extras: {
        slidesPerView: 'auto',
        spaceBetween: 0,
      }
    });
  }
}
