<template>
  <div class="default-page">
    <span style="padding: 0 10px">提示：除礼包列表外，其他模块属于商城公共页面，不可进行设置。如需修改，请到公共页面设置中进行修改。</span>
    <div class="floor-show" :style="{ minHeight: boxMinHeight + 'px' }">
      <div class="floor-item-box" :style="{ background: floorBackground }">
        <div class="floor-item" v-for="floorItem in floorData" :key="floorItem.key">
          <component :is="floorItem.template" :showData="floorItem" :edit-mode="true" />
        </div>
      </div>
    </div>
    <div class="editor-box">
      <gift-editor :dataList="giftData.blockList[0].block_value" @saveData="saveData" @giftNum="getGiftNum" />
    </div>
  </div>
</template>

<script>
import * as API_Floor from "@/api/floor";
import * as API_Combo from "@/api/combo-card-shop/combo";
import GiftEditor from './template-show/gift-editor'

export default {
  name: "default-page",
  components: { GiftEditor },
  data () {
    return {
      floorData: [],
      floorBackground: '#FFF',
      giftData: {
        tpl_id: 6,
        blockList: [{
          block_type: "GOODSLIST",
          block_value: []
        }]
      },
      boxMinHeight: 0
    }
  },
  methods: {
    loadFloor () {
      const cardId = this.$route.params.id === undefined ? null : this.$route.params.id
      const shopId = JSON.parse(localStorage.getItem('shopInfo2021')).shop_id
      // 获取默认页面数据（公共楼层+礼包数据）
      API_Floor.getFloorConfig(shopId, 0, null).then(res => {  // 公共页面数据
        this.$emit('pageId', res.page_id)
        this.floorBackground = res.floor_background || '#FFF'
        let pageData = JSON.parse(res.page_data)
        API_Floor.getFloorConfig(shopId, 1, cardId).then(res => {  // 礼包信息
          const giftData = JSON.parse(res.page_data)
          this.giftData = Array.isArray(giftData) ? giftData[0] : giftData
          if (this.giftData.blockList[0].block_value.length > 0) {
            let ids = []
            this.giftData.blockList[0].block_value.forEach(value => {
              ids.push(value.id)
            })
            API_Combo.getCombos(ids.join(',')).then(combo => {  // 根据礼包id获取数据
              this.giftData.blockList[0].block_value = combo
              pageData.push(this.giftData)
              this.floorData = pageData.map(item => {
                item.template = require(`./template-show/template-${item.tpl_id}`).default
                return item
              })
            })
          } else {
            pageData.push(this.giftData)
            this.floorData = pageData.map(item => {
              item.template = require(`./template-show/template-${item.tpl_id}`).default
              return item
            })
          }
        })
      })
    },
    saveData (e) {
      this.giftData.blockList[0].block_value = JSON.parse(JSON.stringify(e))
      this.$message.success("保存成功")
    },
    getGiftNum (num) {
      if (num > 1 && num < 4) this.boxMinHeight = 280 + (num - 1) * 135
      else if (num >= 4) this.boxMinHeight = 280 + 2 * 135
      else this.boxMinHeight = 0
    },
    publish () {
      return new Promise(resolve => {
        resolve(this.giftData)
      })
    }
  },
  mounted () {
    this.$emit('customFloor', { publish: this.publish })
  },
  created () {
    this.loadFloor()
  }
}
</script>

<style lang="scss" scoped>
.default-page {
  position: relative;

  .floor-show {
    width: 375px;
    padding: 0 10px;
    box-sizing: border-box;

    .floor-item-box {
      margin-top: 25px;
      padding: 10px;
      border-radius: 8px;

      .floor-item {
        margin-bottom: 12px;
      }
    }
  }

  .editor-box {
    width: 600px;
    margin-top: 50px;
    margin-left: 5px;
    position: absolute;
    top: 0;
    left: 375px;
  }
}
</style>
