import {FloorItem, FloorItemProxy} from '@/custom-floor/renovation/services/floor';
import {FloorMenuItem} from '@/custom-floor/renovation/services/floor-menu-item';

export class FloorItem4Proxy extends FloorItemProxy {
  text!: FloorMenuItem;

  constructor(item: FloorItem) {
    super(item);
    // 注册检测是否改变的函数
    item.isChange = () => this.isChange();
  }

  parse(item: FloorItem) {
    this.text = FloorMenuItem.createText(item.blockList[0] || {});
  }

  hasError() {
    return this.text.hasError();
  }

  toJson() {
    return [this.text.toTextJson()];
  }
}
