import {BehaviorSubject} from 'rxjs';

export class Device {
  static all = [
    {
      width: 320,
      height: 568,
      name: 'iPhone5/SE'
    },
    {
      width: 375,
      height: 667,
      name: 'iPhone6/7/8'
    },
    {
      width: 414,
      height: 736,
      name: 'iPhone6/7/8 Plus'
    },
    {
      width: 375,
      height: 812,
      name: 'iPhoneX'
    },
    {
      width: 360,
      height: 640,
      name: 'Galaxy S5'
    },
    {
      width: 411,
      height: 731,
      name: 'Huawei'
    },
  ];

  static get default() {
    return this.all[1];
  }

  static onChange = new BehaviorSubject(Device.default);
}
