/**
 * 优惠券相关API
 */

import request from '@/utils/request'


/**
 * 删除优惠券
 * @param ids
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export function deleteCoupons(ids, params) {
  return request({
    url: `seller/promotion/coupons/${ids}`,
    method: 'delete',
    loading: false,
    params
  })
}

/**
 * 删除优惠券
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export function cardDelete(params) {
  return request({
    url: `/seller/card/delete`,
    method: 'post',
    loading: false,
    params
  })
}


/**
 * 查询卡卷
 * @param id
 * @returns {Promise<any>}
 */
export function getDhmlist(params) {
  return request({
    url: 'seller/card/list',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 添加卡券
 * @param id
 * @returns {Promise<any>}
 */
export function cardAdd(params) {
  return request({
    url: `seller/card/addTh`,
    method: 'post',
    headers: {'Content-Type': 'application/json'},
    loading: true,
    data: params
  })
}

/**
 * 编辑卡卷
 * @param id
 * @returns {Promise<any>}
 */
export function cardEdit(id, params) {
  return request({
    url: `seller/card/editTh/${id}`,
    method: 'post',
    loading: true,
    headers: {'Content-Type': 'application/json'},
    data: params
  })
}

/**
 * 手动导入卡密

 */
export function importCardKeys(params) {
  return request({
    url: 'seller/card/importCardKeys',
    headers: {'Content-Type': 'application/json'},
    method: 'post',
    loading: false,
    data: params
  })
}

/*

查询卡密
*/
export function getDhJcard(params) {
  return request({
    url: 'seller/card/cardKeysList',
    method: 'get',
    loading: false,
    params
  })
}

// 创建卡密
export function createCardKeys(params) {
  return request({
    url: 'seller/card/createCardKeys',
    method: 'post',
    loading: false,
    data: params
  })
}

//查询卡劵生成 日志表
export function cardList(params) {
  return request({
    url: 'seller/card',
    method: 'get',
    loading: false,
    params
  })
}

//卡卷禁用和激活
export function updateCardsKeyStatus(params) {
  return request({
    url: 'seller/card/updateCardsKeyStatus',
    method: 'put',
    loading: false,
    data: params
  })
}

//查询卡卷发放日志表列表
export function getProvideLog(params) {
  return request({
    url: 'seller/card/getProvideLog',
    method: 'get',
    params
  })
}

//在线卡卷发放系统
export function bindingMembers(params) {
  return request({
    url: 'seller/card/bindingMembers',
    method: 'put',
    data: params
  })
}

//卡卷详情
export function getCardmsg(params) {
  return request({
    url: 'seller/card/getCard',
    method: 'get',
    params
  })
}

//修改卡卷
export function editCardmsg(id, params) {
  return request({
    url: `/seller/card/edit/${id}`,
    method: 'put',
    params
  })
}

//
//导入绑定卡密
export function importBindingMembers(params) {
  return request({
    url: 'seller/card/importBindingMembers',
    method: 'post',
    headers: {'Content-Type': 'application/json'},
    data: params
  })
}

//优惠券列表
export function getCouponsList(params) {
  return request({
    url: 'seller/promotion/coupons',
    method: 'get',
    params
  })
}

//添加优惠券
export function addCoupons(params) {
  return request({
    url: 'seller/promotion/coupons',
    method: 'post',
    data: params
  })
}

//修改优惠券
export function modifyCoupons(coupon_id, params) {
  return request({
    url: `seller/promotion/coupons${coupon_id}`,
    method: 'put',
    data: params
  })
}

//查询一个优惠券
export function getCouponDetails(id) {
  return request({
    url: `seller/promotion/coupons${id}`,
    method: 'get',
  })
}

//查询企业下店铺列表
export function getShopChilds() {
  return request({
    url: 'seller/shops/getShopChilds',
    method: 'get',
  })
}

//查询礼品套餐商品列表
export function comboGoods(params) {
  return request({
    url: 'seller/goods/comboGoods',
    method: 'get',
    params
  })
}

//添加商品
export function addComboGoods(params) {
  return request({
    url: 'seller/goods/comboGoods',
    headers: {'Content-Type': 'application/json'},
    method: 'post',
    data: params
  })
}

//方案报价
export function goodsMktList(params) {
  return request({
    url: '/seller/goodsmkt/goodsMkt/page',
    method: 'get',
    params
  })
}

//方案报价详情
export function goodsMktdetail(id) {
  return request({
    url: `/seller/goodsmkt/goodsMkt/${id}`,
    method: 'get',
  })
}

// 修改卡券类型
export function updateStatus(params) {
  return request({
    url: `seller/card/updateStatus`,
    method: 'put',
    params
  })
}

// 查询卡密操作日志列表
export function getCardsKeyOperationLog(params) {
  return request({
    url: `seller/card/cardsKeyOperationLog`,
    method: 'get',
    params
  })
}

// 查询部分商品可用时的选择的商品
export function getSelectedGoods(params) {
  return request({
    url: `seller/promotion/full-discounts/promotionGoods`,
    method: 'get',
    params
  })
}
