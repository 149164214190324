
import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Action, ActionTypes } from '@/custom-floor/renovation/services/action';
import { $goods, GoodsCategory, GoodsProxy } from '@/custom-floor/renovation/services/goods';
import { Floor } from '@/custom-floor/renovation/services/floor';
import GiftCard from '../../../$components/gift-card/gift-card.vue';
import SetHotspot from '@/custom-floor/renovation/modules/$components/floor-menu-item-editor/components/set-hotspot.vue'
import GiftPicker from '@/views/combo-card-shop/card/set-floor/gift-picker/gift-picker.vue'

let $goodsCategories: GoodsCategory[] = [];
let $goodsSpecialSubjects: Floor[] = [];
let $childFloors: Floor[] = [];
let $isInit = false;

@Component({
  name: 'extra-link',
  components: { GiftCard, SetHotspot, GiftPicker }
})
export default class ExtraOperate extends Vue {
  @PropSync('title', { default: '链接' }) realTitle!: string;
  @PropSync('type') realType!: ActionTypes;
  @PropSync('value') realValue!: any;
  @PropSync('desc') realDesc!: string;
  @PropSync('extraData') realExtraData!: any;
  @PropSync('goodsPreviewBottom') realGoodsPreviewBottom!: boolean;
  @PropSync('errorText') realErrorText!: string;
  @PropSync('setHot', { default: true }) realSetHot!: boolean;
  @PropSync('src') realImgSrc!: any;
  @PropSync('floor') realFloor!: Floor;

  opts = JSON.parse(JSON.stringify(Action.all));
  giftData = {};
  goodsCategories = $goodsCategories;
  goodsSpecialSubjects = $goodsSpecialSubjects;
  childFloors = $childFloors;
  giftVisible = false;

  created() {
    if (!$isInit) {
      $isInit = true;
      this.$router.afterEach(() => {
        // 在页面切走的时候置空，可以保证下次进页面的时候数据是最新的
        $goodsCategories = [];
        $goodsSpecialSubjects = [];
        $childFloors = [];
      });
    }

    if (!this.realSetHot) {
      this.opts.forEach((value, index) => {
        if (value.label === '设置热区') {
          this.opts.splice(index, 1)
        }
      })
    }
    if (this.$store.getters.shopInfo.shop_type === 7) {
      this.opts.forEach((value, index) => {
        if (value.label === '外部链接') {
          this.opts.splice(index, 1)
        }
      })
    }
  }

  mounted() {
    switch (this.realType) {
      case 'GIFT':
        if (typeof this.realExtraData === 'string') this.giftData = JSON.parse(this.realExtraData)
        else this.giftData = this.realExtraData
        break;
    }
  }

  emitRecheck() {
    this.$nextTick(() => {
      this.$emit('recheck');
    });
  }

  selectDataHandle(gift) {
    const realGift = {
      id: gift.id,
      shop_id: gift.shop_id,
      web_gift_name: gift.web_gift_name,
      gift_name: gift.gift_name,
      thumbnail: gift.thumbnail,
      combo_price: gift.combo_price,
      enterprise_sum_price: gift.enterprise_sum_price,
      market_sum_price: gift.market_sum_price,
      shop_sum_price: gift.shop_sum_price,
      sum_price: gift.sum_price
    }
    this.realDesc = realGift.web_gift_name
    this.realValue = realGift.id;
    this.realExtraData = JSON.stringify(realGift);
    this.giftData = realGift;
    this.emitRecheck();
  }

  clearSurplusInfo() {
    this.realValue = '';
    this.realDesc = '';
    this.realExtraData = {};
    this.realErrorText = '';
    this.giftData = null!;
    this.emitRecheck();
  }
}
