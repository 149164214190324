
import {Component, PropSync, Vue} from 'vue-property-decorator';
import {FloorMenuItem} from '@/custom-floor/renovation/services/floor-menu-item';
import ExtraLink from './components/extra-link.vue';
import ExtraInput from './components/extra-input.vue';
import ExtraCover from './components/extra-cover.vue';
import ExtraSuggest from './components/extra-suggest.vue';
import {Floor} from "@/custom-floor/renovation/services/floor";

  @Component({
    name: 'floor-menu-item-editor',
    components: {
      ExtraSuggest,
      ExtraCover,
      ExtraInput,
      ExtraLink,
    }
  })
export default class BannerEditorCard extends Vue {
    @PropSync('item', {
      default: null,
      required: true
    }) realItem!: FloorMenuItem;

    @PropSync('coverWidth', {default: 0}) realCoverWidth!: number;
    @PropSync('coverHeight', {default: 0}) realCoverHeight!: number;
    @PropSync('coverSize', {default: 5}) realCoverSize!: number;
    @PropSync('coverDisabled', {default: false}) realCoverDisabled!: boolean;
    @PropSync('suggestDisabled', {default: false}) realSuggestDisabled!: boolean;
    @PropSync('removeText', {default: '删除'}) realRemoveText!: string;
    @PropSync('inputTitle', {default: '备注'}) realInputTitle!: string;
    @PropSync('inputRequired', {default: false}) realInputRequired!: boolean;
    @PropSync('linkTitle', {default: '链接'}) realLinkTitle!: string;
    @PropSync('noBorder', {default: false}) realNoBorder!: boolean;
    @PropSync('linkGoodsPreviewBottom', {default: true}) realLinkGoodsPreviewBottom!: boolean;
    @PropSync('disableAction', {default: false}) realDisableAction!: boolean;
    @PropSync('setHot', {default: true}) realSetHot!: boolean;
    @PropSync('floor') realFloor!: Floor;

    mounted() {
      this.$nextTick(() => {
        this.realItem.extraTitle = this.realInputTitle;
        this.realItem.extraRequired = this.realInputRequired;
      });
    }
}
