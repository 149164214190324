<template>
  <el-dialog title="礼包选择器" :visible.sync="dialogVisible" width="90%" :before-close="handleClose" class="gift-picker"
    :append-to-body="true">
    <div class="search-box">
      <el-form style="display: flex; flex-wrap: wrap;">
        <el-form-item style="width:300px;margin-bottom:0">
          <el-input v-model.trim="searchCondition.lowEnterPrice" size="mini" placeholder="¥ 最小分销总价"></el-input>
          <span style="margin: 0 3px">—</span>
          <el-input v-model.trim="searchCondition.highEnterPrice" size="mini" placeholder="¥ 最大分销总价"
            style="margin-right: 20px">
          </el-input>
        </el-form-item>
        <el-form-item style="width:300px;margin-bottom:0">
          <el-input v-model.trim="searchCondition.lowMktPrice" size="mini" placeholder="¥ 最小市场总价"></el-input>
          <span style="margin: 0 3px">—</span>
          <el-input v-model.trim="searchCondition.highMktPrice" size="mini" placeholder="¥ 最大市场总价"></el-input>
        </el-form-item>
        <el-form-item label="礼包分类" label-width="70px" style="margin-bottom:0">
          <el-cascader clearable style="width: 215px;" v-model="searchCondition.categoryPath"
            :options="JDCategory.children" :props="JDCategory.props" placeholder="请选择礼包分类" class="cas-select" />
        </el-form-item>
        <el-form-item label="套餐类型" label-width="80px" style="margin-bottom: 0;">
          <el-select v-model="searchCondition.comboType" style="width: 100px" clearable>
            <el-option value label="全部"></el-option>
            <el-option :value="1" label="京东"></el-option>
            <el-option :value="2" label="线下品"></el-option>
            <el-option :value="3" label="京东+线下品"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="区域偏好" label-width="80px" style="margin-bottom:0">
          <el-input style="width:180px" placeholder="请输入关键词进行搜索" v-model.trim="searchCondition.labelNamesArea"
            clearable></el-input>
        </el-form-item>
        <el-form-item label-width="10px" style="margin-bottom: 0;">
          <el-dropdown size="small" @command="selectDropdown">
            <el-button size="small" class="search-button">
              {{ searchType }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="礼包名称">礼包名称</el-dropdown-item>
              <el-dropdown-item command="前端礼包名称">前端礼包名称</el-dropdown-item>
              <el-dropdown-item command="商品名称">商品名称</el-dropdown-item>
              <el-dropdown-item command="标签名称">标签名称</el-dropdown-item>
              <el-dropdown-item command="备注信息">备注信息</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-input class="input-search" v-model="keyValue" size="mini" placeholder="请输入关键字进行搜索">
          </el-input>
        </el-form-item>
        <el-form-item label="是否异常" label-width="100px" style="margin-bottom: 0;">
          <el-select v-model="searchCondition.normalStatus" style="width: 100px">
            <el-option value label="全部"></el-option>
            <el-option :value="0" label="异常"></el-option>
            <el-option :value="1" label="正常"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-bottom: 0;">
          <el-button type="primary" size="small" @click="searchHanlde('search')"
            style="margin-left: 15px;">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin-bottom: 10px">
      <el-button v-if="selectType === 'check'" type="primary" size="mini" @click="selectionHanlde">批量挑选
      </el-button>
      <el-checkbox v-if="selectType === 'check'" :style="{ marginLeft: selectType === 'check' ? '20px' : '' }"
        v-model="searchCondition.unselected" @change="searchHanlde('search')">只看未挑选
      </el-checkbox>
    </div>
    <el-table ref="multipleTable" height="330" :data="tableData" tooltip-effect="dark" border style="width: 100%"
      @sort-change="sortChange" @selection-change="handleSelectionChange"
      @select="(selection, row) => $emit('selectionHandle', { selection, row })">
      <el-table-column v-if="selectType === 'check'" type="selection" width="50">
      </el-table-column>
      <el-table-column label="编号" type="index" :index="indexMethod" width="60" show-overflow-tooltip fixed="left">
      </el-table-column>
      <el-table-column label="礼包图片" width="90" fixed="left">
        <template slot-scope="scope">
          <el-popover placement="right" trigger="hover">
            <img :src="scope.row.thumbnail" style="width: 300px" />
            <!-- <img :src="scope.row.thumbnail" slot="reference" style="width: 50px; height: 50px" /> -->
            <div slot="reference">
              <div style="position: relative;width: fit-content;margin: 0 auto;">
                <img :src="scope.row.thumbnail" style="width: 50px; height: 50px" />
                <img v-if="scope.row.normal_status === 0" class="goods-tag" :src="getRowTagImage(scope.row)" alt />
              </div>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column v-if="selectType === 'check'" prop="gift_name" label="礼包名称" show-overflow-tooltip />
      <el-table-column v-else prop="gift_name" label="礼包名称" show-overflow-tooltip fixed="left" />
      <el-table-column prop="web_gift_name" label="前端礼包名称" show-overflow-tooltip />
      <el-table-column label="礼包分类" :key="'category_name'" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.category_name }}
        </template>
      </el-table-column>
      <el-table-column prop="goods_kind_num" label="商品种类" width="90" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="sum_price" label="会员总价" width="105" sortable="custom" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="enterprise_sum_price" label="分销总价" width="105" sortable="custom" show-overflow-tooltip>
      </el-table-column>
      <el-table-column v-if="MixinIsFormEnterprise" label="分销利润率" width="130" prop="distribution_profit"
        sortable="custom" :key="'distribution_profit'">
        <template slot="header">
          <el-tooltip effect="dark" placement="top">
            <label class="cursor-pointer"><i class="el-icon-warning-outline"></i>分销利润率</label>
            <div slot="content">
              分销利润率 = (分销总价-会员总价) /分销总价*100%
            </div>
          </el-tooltip>
        </template>
        <template slot-scope="scope">{{ scope.row.distribution_profit ? scope.row.distribution_profit.toFixed(2) : 0
          }}%</template>
      </el-table-column>
      <el-table-column prop="market_sum_price" label="市场总价" width="105" sortable="custom" show-overflow-tooltip>
      </el-table-column>
      <el-table-column v-if="MixinIsFormEnterprise" label="市场利润率" width="130" prop="market_profit" sortable="custom"
        :key="'market_profit'">
        <template slot="header">
          <el-tooltip effect="dark" placement="top">
            <label class="cursor-pointer"><i class="el-icon-warning-outline"></i>市场利润率</label>
            <div slot="content">
              市场利润率 = (市场总价-会员总价) /市场总价*100%
            </div>
          </el-tooltip>
        </template>
        <template slot-scope="scope">{{ scope.row.market_profit ? scope.row.market_profit.toFixed(2) : 0 }}%</template>
      </el-table-column>
      <el-table-column prop="shop_sum_price" label="销售总价" width="105" sortable="custom" show-overflow-tooltip>
      </el-table-column>
      <el-table-column v-if="MixinIsFormEnterprise" label="销售利润率" width="130" prop="price_profit" sortable="custom"
        :key="'price_profit'">
        <template slot="header">
          <el-tooltip effect="dark" placement="top">
            <label class="cursor-pointer"><i class="el-icon-warning-outline"></i>销售利润率</label>
            <div slot="content">
              销售利润率 = (销售总价-会员总价) /销售总价*100%
            </div>
          </el-tooltip>
        </template>
        <template slot-scope="scope">{{ scope.row.price_profit ? scope.row.price_profit.toFixed(2) : 0 }}%</template>
      </el-table-column>
      <!-- <el-table-column prop="combo_price" label="套餐价" width="90" sortable="custom" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="shop_profit" label="利润率" width="90" sortable="custom" show-overflow-tooltip>
        <template slot-scope="scope">{{ scope.row.shop_profit.toFixed(2) || 0 }}%</template>
      </el-table-column> -->
      <el-table-column label="套餐类型" width="160" prop="combo_type" :key="'combo_type'">
        <template slot-scope="scope">
          <span v-if="scope.row.combo_type == 1">京东</span>
          <span v-else-if="scope.row.combo_type == 2">线下品</span>
          <span v-else-if="scope.row.combo_type == 3">京东+线下品</span>
        </template>
      </el-table-column>
      <el-table-column label="区域偏好" width="160" show-overflow-tooltip prop="label_names_area"
        :key="'label_names_area'" />
      <el-table-column label="标签" width="160" show-overflow-tooltip prop="label_names_gift" :key="'label_names_gift'" />
      <el-table-column label="备注信息" :show-overflow-tooltip="true" width="200" prop="remark" />
      <el-table-column v-if="selectType === 'radio'" label="操作" width="90" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="selectData(scope.row)">挑选</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-box">
      <el-pagination background @size-change="(e) => searchHanlde('size', e)"
        @current-change="(e) => searchHanlde('on', e)" :current-page="searchCondition.page_no"
        :page-sizes="[10, 20, 50, 100]" :page-size="searchCondition.page_size"
        layout="total, sizes, prev, pager, next, jumper" :total="totalNum">
      </el-pagination>
    </div>
  </el-dialog>
</template>

<script>
import * as API_Combo from "@/api/combo-card-shop/combo";
import statusErr from "@/assets/goods/statusErr.png";
import { $giftsCategory } from '@/pages/goods/services/gifts-category'
export default {
  name: "gift-picker",
  props: {
    giftVisible: {
      required: true,
    },
    selectType: {
      default: "check",
    },
    giftData: {
      type: Array,
    },
  },
  watch: {
    giftVisible: {
      handler (newData) {
        this.dialogVisible = newData;
        this.searchCondition.combo_ids = this.giftData.map(item => item.id)
        const rows = this.giftData;
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection();
          if (rows) {
            const list = [];
            rows.forEach((row) => {
              this.tableData.forEach((v) => {
                if (row.id === v.id) {
                  list.push(v);
                  // this.searchCondition.combo_ids.push(v.id);
                }
              });
            });
            // this.searchCondition.combo_ids = [...new Set(this.searchCondition.combo_ids)]
            list.forEach((item) => {
              this.$refs.multipleTable.toggleRowSelection(item, true);
            });
            this.searchHanlde('search')
          }
        });
      },
    },
  },
  data () {
    return {
      JDCategory: $giftsCategory.create(),
      searchCondition: {
        normalStatus: void 0,
        highEnterPrice: null,
        lowEnterPrice: null,
        highMktPrice: null,
        lowMktPrice: null,
        labelNamesArea: null,
        unselected: true,
        categoryPath: '',
        comboType: '',
        // combo_ids: [],
        card_Id: this.$route.params.id,
        sort: "",
        page_no: 1,
        page_size: 10,
        comboStatus: 1,
      },
      tableData: [],
      multipleSelection: [],
      dialogVisible: false,
      totalNum: 0,
      searchType: "礼包名称",
      keyValue: "",
    };
  },
  methods: {
    getRowTagImage (row) {
      if (row.normal_status === 0) return statusErr
    },
    indexMethod (index) {
      return index + 1;
    },
    searchHanlde (type = "search", num = 1) {
      if (type === "search") {
        this.searchCondition.page_no = 1;
        this.searchCondition.page_size = 10;
      } else if (type === "size") this.searchCondition.page_size = num;
      else if (type === "on") this.searchCondition.page_no = num;
      const params = JSON.parse(JSON.stringify(this.searchCondition));
      params.unselected = params.unselected ? 1 : 0;
      switch (this.searchType) {
        case "礼包名称":
          params.gift_name = this.keyValue;
          break;
        case "前端礼包名称":
          params.web_gift_name = this.keyValue;
          break;
        case "商品名称":
          params.goods_name = this.keyValue;
          break;
        case "标签名称":
          params.labelNamesGift = this.keyValue;
          break;
        case "备注信息":
          params.remark = this.keyValue;
          break;
      }
      // if (params.unselected == 0) {
      delete params.combo_ids;
      // }
      API_Combo.getComboList(params).then((res) => {
        this.totalNum = res.data_total;
        this.tableData = res.data;
        this.toggleSelection(this.giftData);
      });
    },
    selectionHanlde () {
      this.$emit("multipleSelection", this.multipleSelection, this.tableData);
      this.$emit("giftVisible", false);
    },
    toggleSelection (rows) {
      this.searchCondition.combo_ids = [];
      this.$nextTick(() => {
        this.$refs.multipleTable.clearSelection();
        if (rows) {
          const list = [];
          rows.forEach((row) => {
            this.tableData.forEach((v) => {
              if (row.id === v.id) {
                list.push(v);
                this.searchCondition.combo_ids.push(v.id);
              }
            });
          });
          this.searchCondition.combo_ids = [...new Set(this.searchCondition.combo_ids)]
          list.forEach((item) => {
            this.$refs.multipleTable.toggleRowSelection(item, true);
          });
        }
      });
    },
    handleSelectionChange (val) {
      // let that = this;
      // let list = val;
      // if(list.length == 0) {
      //   list = this.tableData
      // }
      // console.log(this.tableData);
      // for (let i = 0; i < val.length; i++) {
      //   that.$emit("selectionHandle", { selection: [val[i]], row: val[i] });
      // }
      this.multipleSelection = val;
    },
    handleClose (done) {
      this.$confirm("关闭后将取消修改，是否继续？").then((_) => {
        done();
        this.$emit("giftVisible", false);
      });
    },
    selectData (data) {
      this.$emit("selectData", data);
      this.$emit("giftVisible", false);
    },
    selectDropdown (type) {
      this.searchType = type;
    },
    sortChange (column) {
      const order =
        column.order === "ascending"
          ? "asc"
          : column.order === "descending"
            ? "desc"
            : null;
      if (order) {
        switch (column.prop) {
          case "sum_price":
            this.searchCondition.sort = "memberPrice_" + order;
            break;
          case "enterprise_sum_price":
            this.searchCondition.sort = "enterprisePrice_" + order;
            break;
          case "market_sum_price":
            this.searchCondition.sort = "marketPrice_" + order;
            break;
          case "shop_sum_price":
            this.searchCondition.sort = "shopPrice_" + order;
            break;
          case "combo_price":
            this.searchCondition.sort = "comboPrice_" + order;
            break;
          case "shop_profit":
            this.searchCondition.sort = "profitMargin_" + order;
            break;
        }
        this.searchHanlde("search");
      } else {
        this.searchCondition.sort = ''
        this.searchHanlde("search");
      }
    },
  },
  mounted () {
    const params = JSON.parse(JSON.stringify(this.searchCondition));
    params.unselected = params.unselected ? 1 : 0;
    API_Combo.getComboList(params).then((res) => {
      this.totalNum = res.data_total;
      res.data = res.data.map(item => {
        item.label_names_gift = item.label_names_gift && item.label_names_gift.replaceAll(',', '、')
        item.label_names_area = item.label_names_area && item.label_names_area.replaceAll(',', '、')
        return item
      })
      this.tableData = res.data;
    });
  },
};
</script>

<style lang="scss" scoped>
.goods-tag {
  position: absolute;
  width: 30px;
  top: 0;
  right: 0;
}

.gift-picker {
  .search-box {
    padding: 0;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .el-input {
      width: 130px;
    }
  }

  /deep/ .el-dialog {
    margin-top: 4vh !important;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 0;

    .el-dialog__header {
      position: relative;
      padding: 10px;
      background-color: #e3e5eb;

      .el-dialog__headerbtn {
        top: 10px;
      }

      &:before {
        content: "";
        width: 4px;
        height: 18px;
        position: absolute;
        top: 13px;
        left: 0;
        background-color: #1a43a9;
      }
    }

    .el-dialog__body {
      padding: 20px;
    }
  }

  /deep/ .el-table {
    max-height: calc(80vh - 160px);
    overflow: auto;

    th,
    td {
      text-align: center;
    }

    th {
      background-color: rgb(230, 236, 247);
    }

    td {
      padding: 8px 0;
    }

    &:before {
      height: 0;
    }
  }

  .pagination-box {
    padding-top: 10px;
    display: flex;
    justify-content: center;
  }

  .search-button {
    height: 34px;
    border-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-search {
    width: 175px !important;

    /deep/ .el-input__inner {
      height: 34px;
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>
