<template>
  <div class="template-1">
    <el-carousel height="180px" v-if="showData.blockList[0]" indicator-position="none">
      <el-carousel-item v-for="(slide, index) in showData.blockList[0].block_value" :key="index">
        <img class="show-img" :src="slide.block_value">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "template-1",
  props: {
    showData: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.template-1 {
  background-color: #dddddd;
  border-radius: 8px;

  .show-img {
    width: 100%;
    height: 180px;
    border-radius: 6px;
  }

  /deep/ .el-carousel__container {
    button {
      display: none;
    }
  }
}
</style>
