
import {Component, PropSync, Vue} from 'vue-property-decorator';
import {Subscription} from 'rxjs';
import {GoodsProxy} from '@/custom-floor/renovation/services/goods';
import GoodsPrice from './components/goods-price.vue';
import {Device} from '@/custom-floor/renovation/components/mobile-preview/services/device';
import {$copy} from '@/custom-floor/renovation/services/copy';
import { getGoodsInfo, getPriceUnit } from '../../../api';
// getPriceUnit
@Component({
  name: 'goods-card',
  components: {GoodsPrice}
})
export default class GoodsCard extends Vue {
  @PropSync('goods', {required: true}) realGoods!: GoodsProxy;
  @PropSync('direction', {default: 'vertical'}) realDirection!: 'horizontal' | 'vertical';
  @PropSync('sbGoods', {default: false}) realSbGoods!: boolean;

  emptyName = new Array(50).fill('空').join('');
  subscriptions: Subscription[] = [];
  imageMinSize = '';
  imageMaxSize = '';
  priceUnit = '';
  unitSuffix = false;
  imageEl = null! as HTMLImageElement;
  alterGood = {}
  from_enter_seller_shop:any = localStorage.getItem('shopInfo2021')
  created() {
    const shop_id = JSON.parse(this.from_enter_seller_shop).shop_id
    // console.log(JSON.parse(this.from_enter_seller_shop).shop_id)
    // console.log(shop_id, this.realGoods)
    if (shop_id && this.realGoods.id) {
      // console.log(this.realGoods)
      getGoodsInfo({
        shop_id,
        good_ids: String(this.realGoods.id)
      }).then(res => {
        const obj = {
          ...this.realGoods.originalData,
          ...res[0]
        }
        this.alterGood = GoodsProxy.create(obj)
        this.$emit("getGoods", this.alterGood);
        // console.log(this.alterGood)
      })
    }
    this.subscriptions = [
      Device.onChange.subscribe(() => {
        this.calcHeight();
      })
    ];

    getPriceUnit().then(res => {
      this.priceUnit = res.monetary_unit || '¥'
      if (res.monetary_unit) this.unitSuffix = true;
      else this.unitSuffix = false
    })
  }

  mounted() {
    this.calcHeight();
  }

  destroyed() {
    this.subscriptions.map(s => s.unsubscribe());
  }

  calcHeight(e?: any) {
    const el: any = this.$el || (e ? e.terget || e.path[0] : null);
    if (el && this.realDirection === 'vertical') {
      this.imageMinSize = `${el.clientWidth}px`;
      this.imageMaxSize = `${el.clientWidth * 1.4}px`;
    }
  }

  copyGoodsName() {
    $copy.copy(this.realGoods.name).then(() => {
      this.$message.success('礼包名称已复制');
    }, err => {
      this.$message.error('复制失败');
      console.log('复制失败', err);
    });
  }
}
