<template>
  <div class="template-6 row no-gutters">
    <template v-if="giftList.length > 0">
      <div class="col-6">
        <template v-for="(gift, index) in giftList">
          <gift-card v-if="index % 2 === 0" :giftData="gift" :key="index"/>
        </template>
      </div>
      <div class="col-6">
        <template v-for="(gift, index) in giftList">
          <gift-card v-if="index % 2 === 1" :giftData="gift" :key="index"/>
        </template>
      </div>
    </template>
    <placeholder v-else/>
  </div>
</template>

<script>
import GiftCard from "@/combo-floor/renovation/modules/$components/gift-card/gift-card";
import Placeholder from "@/combo-floor/renovation/modules/6/placeholder";

export default {
  name: "template-6",
  components: {
    GiftCard,
    Placeholder
  },
  props: {
    showData: {
      type: Object
    }
  },
  watch: {
    showData: {
      deep: true,
      handler(newData) {
        this.giftList = newData.blockList[0].block_value
      }
    }
  },
  data() {
    return {
      giftList: this.showData.blockList[0].block_value,
    }
  }
}
</script>

<style scoped>

</style>
