<template>
  <div class="template-4">
    <h4 style="margin: 0" v-if="showData.blockList[0] && showData.blockList[0].block_value"
        v-text="showData.blockList[0].block_value"></h4>
    <div v-else style="width: 90px;height: 25px;background-color: #ddd;border-radius: 6px"></div>
  </div>
</template>

<script>
export default {
  name: "template-4",
  props: {
    showData: {
      type: Object
    }
  }
}
</script>
