
import {Component, Mixins} from 'vue-property-decorator';
import {TemplateExtra} from '../module';
import {LineProxy} from '@/custom-floor/renovation/modules/8/$proxy';

@Component({
  name: 'template-8'
})
export default class Template extends Mixins(TemplateExtra) {
  line: LineProxy = null!;

  init() {
    this.data.onChange.subscribe(block => {
      const list = block.list || this.data.blockList || [];
      this.line = new LineProxy(list[0]);
    });
  }
}
