
import {Component, PropSync, Vue} from 'vue-property-decorator';
import draggable from 'vuedraggable';
import {$floorModules} from '@/custom-floor/renovation/modules';
import {FloorModule} from '../../modules/module.config';
import {Floor, FloorItem} from '@/custom-floor/renovation/services/floor';
import BackgroundRender from '../background-render/background-render.vue';

@Component({
  name: 'floor-module-panel',
  components: {
    BackgroundRender,
    draggable,
  },
})
export default class FloorModulePanel extends Vue {
  @PropSync('floor') realFloor!: Floor;
  modules:any = [];

  draggableOptions = {
    sort: false,
    group: {
      name: 'floor',
      pull: 'clone',
      put: false
    },
  };

  clone(item: FloorModule) {
    return FloorItem.create(item, true);
  }

  created() {
    this.modules = $floorModules.modules
  }

  mounted() {
    const unwatch = this.$watch('isReady', function (newValue) {
      console.log(newValue);
      unwatch()
    });
  }
}
