<template>
  <div class="custom-floor">
    <div class="radio-box">
      <h3 style="margin-right: 30px;margin-bottom: 0">页面设置类型选择:</h3>
      <span @click="selectHandle('1')">
              <el-radio v-model="selectPage" label="1" style="margin-top:5px;margin-bottom: 0">使用默认页面样式</el-radio>
      </span>
      <span style="margin-left: 20px" @click="selectHandle('2')">
              <el-radio v-model="selectPage" label="2" style="margin-top:5px;margin-bottom: 0">使用自定义页面</el-radio>
      </span>
    </div>
    <div class="card-name" style="margin-top: 20px">
      <h3 style="margin:0 10px 0 2em">当前卡券名称:</h3>
      <span v-text="cardName"></span>
    </div>
    <div class="floor-box">
      <default-page v-if="pageType=='1'" @customFloor="e=>$emit('customFloor',e)" @pageId="e=>$emit('pageId',e)"/>
      <floor-renovation v-else @customFloor="e=>$emit('customFloor',e)" @pageId="e=>$emit('pageId',e)"/>
    </div>
  </div>
</template>

<script>
import DefaultPage from './default-page'
import FloorRenovation from '../../../../custom-floor/renovation/floor-renovation'

export default {
  name: "custom-floor",
  components: {DefaultPage, FloorRenovation},
  props: {
    floorPageType: {
      required: true
    },
    cardName: {
      required: true
    }
  },
  data() {
    return {
      pageType: '1',
      selectPage: '1',
      handleStatus: false
    }
  },
  watch: {
    pageType: {
      handler(newData) {
        this.$emit('selectPage', newData)
      },
      immediate: true
    },
    floorPageType: {
      handler(newData) {
        this.pageType = newData + ''
        this.selectPage = newData + ''
      },
      immediate: true
    }
  },
  methods: {
    selectHandle(type) {
      if (this.handleStatus) return
      this.handleStatus = true
      this.$confirm('当前页面数据未存储。切换后，再次选择进入该页面时，需重新装修楼层数据。是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.pageType = type
        this.handleStatus = false
      }).catch(() => {
        this.selectPage = this.pageType
        this.$message({
          type: 'info',
          message: '已取消切换'
        });
        this.handleStatus = false
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-floor {
  min-height: calc(100vh - 165px);
  padding-top: 20px;
  background-color: #FFF;
  box-sizing: border-box;

  .radio-box, .card-name {
    padding: 0 20px;
    display: flex;
    align-items: center;
  }

  .floor-box {
    margin-top: 20px;
    padding: 20px;
    border-top: 1px solid #f1f1f1;
  }

  /deep/ .floor-module-panel {
    position: relative !important;
    top: 10px !important;
  }

  /deep/ .master-control-panel {
    background: #FFF;
  }
}
</style>
