
import {Component, Mixins} from 'vue-property-decorator';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {TemplateExtra} from '../module';
import {SwiperProxy} from '../../services/swiper';
import GiftCard from '../$components/gift-card/gift-card.vue';
import Placeholder from './placeholder.vue';
import * as API_Combo from "@/api/combo-card-shop/combo";

@Component({
  name: 'template-5',
  components: {
    Swiper,
    SwiperSlide,
    GiftCard,
    Placeholder
  }
})
export default class Template extends Mixins(TemplateExtra) {
  giftList: any = [];
  swiperProxy: SwiperProxy = null!;

  init() {
    this.swiperProxy = SwiperProxy.create({
      instance: this,
      key: this.data.key,
      refKey: 'swiper',
      extras: {
        slidesPerView: 'auto',
        spaceBetween: 0,
      }
    });
    this.data.onChange.subscribe(block => {
      const list = block.list || this.data.blockList || [];
      const first = list[0];
      if (first?.block_type === 'GIFTLIST' && first.block_value.length) {
        const ids = first.block_value.map(item => {
          return item.id
        })
        API_Combo.getCombos(ids.join(',')).then(res => {
          this.giftList = res;
        })
      } else if (first?.block_type === 'GIFTLIST') {
        this.giftList = [];
      }
    });
  }
}
