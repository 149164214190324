import {FloorItem, FloorItemProxy} from '@/custom-floor/renovation/services/floor';

export class FloorItem5Proxy extends FloorItemProxy {
  giftList!: [];

  constructor(item: FloorItem) {
    super(item);
    // 注册检测是否改变的函数
    item.isChange = () => this.isChange();
  }

  parse(item: FloorItem) {
    const block = item.blockList[0];
    if (!block || !block.block_value) {
      this.giftList = [];
    } else {
      this.giftList = block.block_value
    }
  }

  hasError() {
    return false;
  }

  toJson() {
    return [
      {
        block_type: 'GIFTLIST',
        block_value: this.giftList
      }
    ];
  }
}
