
import {Component, PropSync, Vue} from 'vue-property-decorator';
import draggable from 'vuedraggable';
import {$floor, Floor} from '@/custom-floor/renovation/services/floor';
import {Device} from './services/device';
import MasterControlPanel from './components/master-control-panel.vue';
import SearchBox from './components/search-box.vue';

@Component({
  name: 'mobile-preview',
  components: {
    SearchBox,
    MasterControlPanel,
    draggable,
  },
})
export default class MobilePreview extends Vue {
  @PropSync('floor') realFloor!: Floor;
  @PropSync('isEditing') realIsEditing!: boolean;
  @PropSync('disableAction', {default: false}) realDisableAction!: boolean;
  device = Device.default;
  draggableOptions = {
    sort: true,
    animation: 375,
    group: {
      name: 'floor',
      put: true,
    },
  };

  backgroundColor: any = '#FFFFFF';

  deviceChanged(device: any) {
    this.device = device;
  }

  backShowHandle(value) {
    if (value) {
      if (this.$route.path.split('/')[2] === 'child') this.backgroundColor = sessionStorage.getItem("reeditChildBackground");
      else this.backgroundColor = sessionStorage.getItem("reeditBackground");
    } else this.backgroundColor = '#FFF'
  }

  floorResorted(e: any) {
    if (e.added) e.added.element.edit();
    if (e.moved) e.moved.element.edit();
  }

  mounted() {
    // 监听背景色状态
    $floor.setBackground.subscribe(value => {
      if (value?.type === 'setBack' || value?.type === 'setChildBack') this.backgroundColor = value.data
    })
  }
}
