
// @ts-nocheck
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { EditorExtra } from '../module';
import config from './$config';
import { FloorItem11Proxy } from './$proxy';
import FloorEditorFrame from '../$components/floor-editor-frame/floor-editor-frame.vue';
import CfygCkeditor from '../../components/cfyg-ckeditor/cfyg-ckeditor.vue';
import { $album } from '@/custom-floor/renovation/components/album/services/album.service';
import OSS from 'ali-oss'
import { getCkeditor } from '../../api';

@Component({
  name: 'editor-11',
  components: {
    FloorEditorFrame,
    CfygCkeditor
  }
})
export default class Editor extends Mixins(EditorExtra) {
  config = config;
  proxy: FloorItem11Proxy = null!;
  uploadUrl = $album.uploadUrl;

  @Watch('proxy.richText', { deep: true, immediate: true })
  watchFloor() {
    this.updatePage()
  }

  inputChange(editorData) {
    if (editorData) {
      const dom = document.createElement('div')
      dom.innerHTML = editorData
      this.proxy.richText[0].content = dom.innerHTML;
      const imgList = dom.querySelectorAll('img')
      imgList.forEach(img => {
        const srcType = img.src.substring(0, 4)
        if (srcType !== 'http') {
          const file = this.dataURLtoFile(img.src)
          const filename = new Date().getTime() + '.png'
          const client = new OSS({
            endpoint: 'oss-cn-beijing.aliyuncs.com',
            accessKeyId: 'LTAI4FrvHJnybH5Yoyjg9TQ7',
            accessKeySecret: 'ebza9JREtEV1SKgkBYez5NN8xarnq4',
            bucket: 'sshlwap'
          })
          client.put(filename, file).then(res => {
            if (res.url) {
              img.src = res.url
              this.proxy.richText[0].content = dom.innerHTML
            } else {
              this.$message.error('文件上传失败')
            }
          }).catch(err => {
            console.log(err)
          })
        }
      })
    }
  }

  /**
   * 将base64转换为文件
   * @param dataurl
   * @param filename
   */
  dataURLtoFile(dataurl, filename = '') {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1];
    if (!filename) { // 若无文件名则取当前时间戳
      filename = new Date().getTime() + '.png';
    }
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  updatePage() {
    // setTimeout(() => {
    const data = this.proxy.richText[0].content
    if (data.substring(0, 4) === 'ext_') {
      const fromData: any = localStorage.getItem('shopInfo2021')
      const paramsData = {
        shop_id: JSON.parse(fromData).shop_id,
        ext_ids: this.proxy.richText[0].content
      }
      getCkeditor(paramsData).then(res => {
        this.proxy.richText[0].content = res[0].content
        this.proxy.onSave.next({ silence: true })
      })
    }
    // }, 500)
  }

  init(): any {
    this.proxy = new FloorItem11Proxy(this.realItem);
  }
}
