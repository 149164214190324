
import {Component, Mixins} from 'vue-property-decorator';
import {TemplateExtra} from '../module';
import {GoodsProxy} from '@/custom-floor/renovation/services/goods';
import GiftCard from '../$components/gift-card/gift-card.vue';
import Placeholder from './placeholder.vue';
import * as API_Combo from "@/api/combo-card-shop/combo";

@Component({
  name: 'template-14',
  components: {
    GiftCard,
    Placeholder
  }
})
export default class Template extends Mixins(TemplateExtra) {
  giftList: any = [];
  frontData: GoodsProxy[] = [];
  frontPageSize = 20;
  frontPageIndex = 0;
  frontHasNextPage = true;

  init() {
    this.data.onChange.subscribe(block => {
      const list = block.list || this.data.blockList || [];
      const first = list[0];
      if (first?.block_type === 'GIFTLIST' && first.block_value.length) {
        const ids = first.block_value.map(item => {
          return item.id
        })
        API_Combo.getCombos(ids.join(',')).then(res => {
          this.giftList = res;
        })
      } else if (first?.block_type === 'GIFTLIST') {
        this.giftList = [];
      }
    });
  }

  resetPage() {
    this.frontHasNextPage = true;
    this.frontPageIndex = 0;
    this.frontData = [];
  }

  loadNextPage() {
    if (!this.frontHasNextPage) return;
    if (this.giftList.length === 0) return;

    const startIndex = this.frontPageIndex * this.frontPageSize;
    let endIndex = (this.frontPageIndex + 1) * this.frontPageSize;
    if (endIndex > this.giftList.length) {
      endIndex = this.giftList.length;
      this.frontHasNextPage = false;
    }

    this.frontData = [...this.frontData, ...this.giftList.slice(startIndex, endIndex)];
    this.frontPageIndex++;
  }
}
