<template>
  <div class="template-13">
    <div class="empty-img" v-if="!videoInfo.url">
      <img src="/icons/play-back.png">
    </div>
    <div class="video-show" v-else>
      <video :src="videoInfo.url" :poster="videoInfo.cover" controls></video>
    </div>
  </div>
</template>

<script>
export default {
  name: "template-13",
  props: {
    showData: {
      type: Object
    }
  },
  data() {
    return {
      videoInfo: {}
    }
  },
  created() {
    if (this.showData.blockList[0]) this.videoInfo = this.showData.blockList[0].block_value
  }
}
</script>

<style lang="scss" scoped>
.template-13 {
  .empty-img {
    height: 180px;
    position: relative;
    background: #ddd;
    border-radius: 6px;

    img {
      width: 50px;
      height: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -25px;
      margin-left: -25px;
    }
  }

  .video-show {
    position: relative;
    padding-top: 56.25%;

    video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: fill;
      pointer-events: auto;
      border-radius: 6px;
    }
  }
}
</style>
