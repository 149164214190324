
import {Component, PropSync, Vue} from 'vue-property-decorator';

@Component({
  name: 'goods-price'
})
export default class GoodsPrice extends Vue {
  @PropSync('unitSuffix') realUnitSuffix!: boolean;
  @PropSync('unit', {default: '￥'}) realUnit!: boolean;
  @PropSync('priceInt') realPriceInt!: number;
  @PropSync('priceFloat') realPriceFloat!: number;
  @PropSync('color') realColor!: string;
  @PropSync('sbGoods', {default: false}) realSbGoods!: boolean;
  @PropSync('revisePrice', {default: false}) realRevisePrice;

  revisePriceT = '00'

  get floatPrice() {
    if (this.realSbGoods && this.realRevisePrice.split('.')[1]) return this.realRevisePrice.split('.')[1];
    else if (this.realSbGoods) return '00';
    const price = parseInt(this.realPriceFloat + '');

    if (!price || price === 0) {
      return '00';
    }

    return this.realPriceFloat;
  }

  mounted() {
    if (this.realSbGoods) this.revisePriceT = this.realRevisePrice.split('.')[0]
  }
}
