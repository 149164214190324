
import {Component, PropSync, Vue, Watch} from 'vue-property-decorator';
import {Device} from '../services/device';
import SetBackgroundColor from "@/custom-floor/renovation/components/mobile-preview/components/set-background-color.vue";
import {$floor, Floor} from "@/custom-floor/renovation/services/floor";

  @Component({
    name: 'master-control-panel',
    components: {SetBackgroundColor}
  })
export default class MasterControlPanel extends Vue {
    @PropSync('width') realWidth!: number;
    @PropSync('floor') realFloor!: Floor;

    emulatedDevices = Device.all;
    editorBackShow = false;
    backDisabled = false;
    backShow = true;

    device = Device.default;

    @Watch('realFloor', {immediate: true})
    watchFloor(newFloor) {
      if (newFloor) {
        this.realFloor.floorSwitch.subscribe(value => {
          const data = value && this.backShow
          this.backDisabled = !value
          this.$emit('backShow', data)
        })
      }
    }

    @Watch('$route', {immediate: true, deep: true})
    watchRoute(newRoute) {
      if (newRoute.path.split('/')[2] !== 'subject') this.backShow = true;
      else this.backShow = false
      const data = !this.backDisabled && this.backShow
      this.$emit('backShow', data)
    }

    selectDevice(device: any) {
      this.device = device || Device.default;
      this.$emit('deviceChanged', this.device);
      Device.onChange.next(device);
    }

    /**
     * 开启背景色设置
     */
    editorBackground() {
      $floor.setBackground.next('setBack')
      $floor.setBackground.next('setBack_key')
      this.editorBackShow = true
    }

    mounted() {
      // 关闭背景色设置
      $floor.setBackground.subscribe(value => {
        if (value === 'close') this.editorBackShow = false
      })
    }
}
