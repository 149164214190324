
import {Component, PropSync, Vue} from "vue-property-decorator";
import ElevatorAddFloor from "./elevator-add-floor.vue";
import {Floor} from "@/custom-floor/renovation/services/floor";

  @Component({
    name: 'elevator-editor',
    components: {ElevatorAddFloor}
  })
export default class ElevatorEditor extends Vue {
    @PropSync('elevatorDataList', {
      required: true,
      default: () => []
    }) realElevatorDataList;

    @PropSync('floor') realFloor!: Floor;
}
