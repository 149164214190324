
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'system-status-bar',
})
export default class SystemStatusBar extends Vue {
  battery: { level: number, charging?: boolean; } = {level: 1};
  heartId: any = null!;
  nav: any = navigator;

  @Prop({default: false}) h12!: boolean;

  mounted() {
    const nav: any = navigator;
    nav.getBattery && nav.getBattery().then(battery => {
      this.battery = battery;
    });

    this.heartId = setInterval(() => {
      this.$forceUpdate();
    }, 1000);
  }

  destroyed() {
    clearInterval(this.heartId);
  }

  getNow() {
    const now = new Date();
    const m = now.getMinutes();
    return `${now.getHours() - (this.h12 && now.getHours() > 12 ? 12 : 0)}:${m < 10 ? `0${m}` : m}  ${this.h12 ? (now.getHours() > 12 ? 'PM' : 'AM') : ''}`;
  }

  getBatteryLevel() {
    return this.battery ? Math.ceil(this.battery.level * 100) : 100;
  }

  loadBatteryWidth() {
    return 23 * this.battery.level + 'px';
  }

  loadBatteryBackground() {
    return this.battery.charging ? '#56CE57' : '';
  }
}
