
import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';
import { GoodsProxy } from '@/combo-floor/renovation/services/goods';
import GiftCard from '@/combo-floor/renovation/modules/$components/gift-card/gift-card.vue';
import GiftPicker from '../gift-picker/gift-picker.vue'

@Component({
  name: 'gift-editor',
  components: {GiftCard, GiftPicker}
})
export default class GoodsEditor extends Vue {
  @PropSync('dataList', {
    required: true,
    default: () => []
  }) realGiftList!: GoodsProxy[];

  giftList: any = []
  giftVisible = false

  @Watch('realGiftList', {deep: true})
  getGift(newGift) {
    this.giftList = JSON.parse(JSON.stringify(newGift))
  }

  multipleSelection(list=[],fl=[]) {
    // console.log(list,fl);
    if(fl.length>0 && list.length<=0) {
      for (let i = this.giftList.length -1 ; i >= 0; i--) {
        let id = this.giftList[i].id;
        if(fl.find(item => item["id"] == id)) {
          this.giftList.splice(i, 1)
        }
      }
      return false;
    }
    let _data = [...this.giftList,...list];
    const idSet = new Set(_data.map(item => item.id));
    const uniqueData = Array.from(idSet, id => _data.find(item => item.id == id));
    this.giftList = uniqueData;
  }

  selectionHandle(data) {
    if (data.selection.length) {
      data.selection.forEach((item, index) => {
        if (item.id === data.row.id) {
          this.giftList.push(data.row)
        } else if (index === (data.selection.length - 1)) {
          const getSelect = JSON.parse(JSON.stringify(this.giftList))
          getSelect.forEach((value, index) => {
            if (value.id === data.row.id) this.giftList.splice(index, 1)
          })
        }
      })
    } else {
      const getSelect = JSON.parse(JSON.stringify(this.giftList))
      getSelect.forEach((value, index) => {
        if (value.id === data.row.id) this.giftList.splice(index, 1)
      })
    }

    this.$emit('giftNum', this.giftList.length)
  }

  moveGoodsToTop(index = 0, disabled = false) {
    if (disabled) return;
    const goods = this.giftList[index];
    this.giftList.splice(index, 1)
    this.giftList.unshift(goods);
  }

  moveGoodsToPrev(index = 0, disabled = false) {
    if (disabled) return;
    const goods = this.giftList[index];
    this.giftList.splice(index, 1)
    this.giftList.splice(index - 1, 0, goods);
  }

  moveGoodsToNext(index = 0, disabled = false) {
    if (disabled) return;
    const goods = this.giftList[index];
    this.giftList.splice(index, 1)
    this.giftList.splice(index + 1, 0, goods);
  }

  moveGoodsToBottom(index = 0, disabled = false) {
    if (disabled) return;
    const goods = this.giftList[index];
    this.giftList.splice(index, 1)
    this.giftList.push(goods);
  }
}
