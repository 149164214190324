/**
 * 套餐卡券API
 */
import request from '@/utils/request'

/**
 * 获取指定卡券信息
 * @param params
 * @returns {Promise<any>}
 */
export function getOneCardTh(cardId) {
  return request({
    url: `seller/card/combo/getOneCardTh/${cardId}`,
    method: 'post',
  })
}

/**
 * 在线发放卡卷系统发放
 * @param params
 * @returns {Promise<any>}
 */
export function bindingMembers(data) {
  return request({
    url: 'seller/card/combo/bindingMembersTh',
    method: 'put',
    data
  })
}

/**
 * 接收套餐卡编辑页分享内容
 * @param params
 * @returns {Promise<any>}
 */
export function receiveShare(data) {
  return request({
    url: 'seller/card/combo/receiveShare',
    method: 'put',
    data
  })
}

/**
 * 在线生成卡密
 * @param params
 * @returns {Promise<any>}
 */
export function createCardKeys(data) {
  return request({
    url: 'seller/card/combo/createCardKeysTh',
    method: 'post',
    data
  })
}

/**
 * 卡密备注
 * @param params
 * @returns {Promise<any>}
 */
export function remarkCardKeyTh(data) {
  return request({
    url: `/seller/card/combo/remarkCardKeyTh/${data.id}`,
    method: 'post',
    data
  })
}

/**
 * 卡密导出
 * @param params
 * @returns {Promise<any>}
 */
export function exportOut (data) {
  return request({
    url: 'seller/card/combo/exportOutTh',
    method: 'post',
    params:data
  })
}

/**
 * 导入绑定卡密
 * @param params
 * @returns {Promise<any>}
 */
export function importBindingMembers(data) {
  return request({
    url: 'seller/card/combo/importBindingMembersTh',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * 导入卡密
 * @param params
 * @returns {Promise<any>}
 */
export function importCardKeys(data) {
  return request({
    url: 'seller/card/combo/importCardKeys',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * 导入延期数据
 * @param params
 * @returns {Promise<any>}
 */
export function importExpKeys(data) {
  return request({
    url: 'seller/card/combo/importExpKeysTh',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * 查询套餐卡密列表  GET
 * @param params
 * @returns {Promise<any>}
 */
export function keyList(params) {
  return request({
    url: 'seller/card/combo/keyListTh',
    method: 'get',
    params
  })
}

/**
 * 查询套餐卡密列表Th
 * @param params
 * @returns {Promise<any>}
 */
export function getListMoveTh(cardId,params) {
  return request({
    url: `seller/card/combo/getListMoveTh/${cardId}`,
    method: 'get',
    params
  })
}

/**
 * 查询店铺套餐卡券列表  GET
 * @param params
 * @returns {Promise<any>}
 */
export function getCardList(params) {
  return request({
    url: 'seller/card/combo/listTh',
    method: 'get',
    params
  })
}

/**
 * 查询店铺套餐卡券列表  GET 旧有接口
 * @param params
 * @returns {Promise<any>}
 */
 export function getCardListOld(params) {
  return request({
    url: 'seller/card/list',
    method: 'get',
    params
  })
}

/**
 * 查询用户套餐卡密列表  GET
 * @param params
 * @returns {Promise<any>}
 */
export function memberComboKeys(params) {
  return request({
    url: 'seller/card/combo/memberComboKeys',
    method: 'get',
    params
  })
}

/**
 * 导出套餐卡二维码
 * @param params
 * @returns {Promise<any>}
 */
export function qrExport(params) {
  return request({
    url: 'seller/card/combo/qrExportTh',
    method: 'get',
    params
  })
}

/**
 * 添加套餐卡券
 * @param params
 * @returns {Promise<any>}
 */
export function addCard(data) {
  return request({
    url: 'seller/card/addTh',
    method: 'post',
    data
  })
}

/**
* 查询店铺套餐卡券,编辑时使用
* @param params
* @returns {Promise<any>}
*/
export function getCard(params) {
  return request({
    url: `seller/card/combo/getCardTh`,
    method: 'get',
    params
  })
}

/**
* 删除店铺套餐卡券
* @param params
* @returns {Promise<any>}
*/
export function delCard(ids) {
  return request({
    url: `seller/card/combo/delete`,
    method: 'post'
  })
}

/**
* 编辑店铺套餐卡券
* @param params
* @returns {Promise<any>}
*/
export function editCard(id, data) {
  return request({
    url: `seller/card/combo/edit/${id}`,
    method: 'put',
    data
  })
}

/**
* 更新卡券状态 0：下架 1：上架
* @param params
* @returns {Promise<any>}
*/
export function changeStatus(data) {
  return request({
    url: `seller/card/updateStatusTh`,
    method: 'put',
    data
  })
}

/**
* 卡密激活和禁用
* @param params
* @returns {Promise<any>}
*/
export function changeKeyStatus(data, params) {
  return request({
    url: `seller/card/combo/updateStatusTh`,
    method: 'put',
    data,
    params,
    headers: { 'Content-Type': 'application/json' }
  })
}


/**
* 卡密延期
* @param params
* @returns {Promise<any>}
*/
export function expCardsKey(data, params) {
  return request({
    url: `seller/card/combo/cardExpCardsKeyTh`,
    method: 'put',
    data,
    params,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
* 卡密转移
* @param params
* @returns {Promise<any>}
*/
export function transferCardsKey(params) {
  return request({
    url: `seller/card/combo/transKeys`,
    method: 'get',
    params
  })
}

/**
* 查询批次列表-卡密管理  （卡券发放按钮跳转批次页面）
* @param params
* @returns {Promise<any>}
*/
export function gitsellerCard(params) {
  return request({
    url: `seller/card`,
    method: 'get',
    params
  })
}
/**
* 查询批次列表-在线发放  （卡券发放按钮跳转批次页面）
* @param params
* @returns {Promise<any>}
*/
export function getProvideLog(params) {
  return request({
    url: `seller/card/getProvideLog`,
    method: 'get',
    params
  })
}