import {FloorItem, FloorItemProxy} from '@/custom-floor/renovation/services/floor';

export class FloorItem10Proxy extends FloorItemProxy {
  announcement;

  constructor(item: FloorItem) {
    super(item);
    // 注册检测是否改变的函数
    item.isChange = () => this.isChange();
  }

  parse(item: FloorItem) {
    const block = item.blockList[0];
    if (block && block.block_value) {
      this.announcement = JSON.parse(JSON.stringify(block.block_value))
    } else {
      this.announcement = [{
        content: '',
        boxBack: '#FFFFFF00',
        textColor: '#666666'
      }];
    }
  }

  hasError() {
    if (this.announcement[0].content) return false;
    else return true;
  }

  toJson() {
    return [
      {
        block_type: 'ANNOUNCEMENT',
        block_value: this.announcement,
      }
    ];
  }
}
