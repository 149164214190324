
// @ts-nocheck
import {Component, Mixins} from 'vue-property-decorator';
import {EditorExtra} from '../module';
import config from './$config';
import {FloorItem14Proxy} from './$proxy';
import FloorEditorFrame from '../$components/floor-editor-frame/floor-editor-frame.vue';
import GoodsEditor from '../$components/goods-editor/goods-editor.vue';

@Component({
  name: 'editor-14',
  components: {
    GoodsEditor,
    FloorEditorFrame,
  }
})
export default class Editor extends Mixins(EditorExtra) {
  config = config;
  proxy: FloorItem14Proxy = null!;

  init(): any {
    this.proxy = new FloorItem14Proxy(this.realItem);
    this.proxy.onResetCallBack = () => {
      const editor = this.$refs.editor as any;
      editor.selectSort();
    };
  }
}
