import {FloorItem, FloorItemProxy} from '@/custom-floor/renovation/services/floor';

export class FloorItem11Proxy extends FloorItemProxy {
  richText;

  constructor(item: FloorItem) {
    super(item);
    // 注册检测是否改变的函数
    item.isChange = () => this.isChange();
  }

  parse(item: FloorItem) {
    const block = item.blockList[0];
    if (block) {
      this.richText = JSON.parse(JSON.stringify(block.block_value))
    } else {
      this.richText = [{
        content: '',
        boxBack: '#FFFFFF00'
      }];
    }
  }

  hasError() {
    if (this.richText[0].content) return false;
    else return true;
  }

  toJson() {
    return [
      {
        block_type: 'RICHTEXT',
        block_value: this.richText,
      }
    ];
  }
}
