
import { Component, PropSync, Vue } from 'vue-property-decorator';
import { Subject } from 'rxjs';
import GiftCard from '../gift-card/gift-card.vue';
import FloorEditorFrame from '../floor-editor-frame/floor-editor-frame.vue';
import GiftPicker from '@/views/combo-card-shop/card/set-floor/gift-picker/gift-picker.vue'
import * as API_Combo from "@/api/combo-card-shop/combo";

@Component({
  name: 'goods-editor',
  components: {
    FloorEditorFrame,
    GiftCard,
    GiftPicker
  }
})
export default class GoodsEditor extends Vue {
  @PropSync('title', {default: ''}) realTitle!: string;
  @PropSync('disableAction', {default: false}) realDisableAction!: boolean;
  @PropSync('onSave') realOnSave!: Subject<any>;
  @PropSync('onReset') realOnReset!: Subject<any>;
  @PropSync('onRemove') realOnRemove!: Subject<any>;
  @PropSync('giftList', {required: true, default: () => []}) realGiftList: any;
  @PropSync('isDelete', {default: false}) realIsDelete;

  sortData:any = []
  sorts = [
    {
      icon: 'el-icon-sort-up',
      label: '升序',
      command: 'up',
    },
    {
      icon: 'el-icon-sort-down',
      label: '降序',
      command: 'down',
    },
    {
      icon: 'el-icon-sort',
      label: '无序',
      command: '',
      hidden: true,
    },
  ];

  page_no = 0;
  page_size = 20;
  giftVisible = false

  currentSort = this.sorts[2];

  created() {
    if (this.realGiftList.length) {
      const list = this.realGiftList.map(item => {
        return item.id
      })
      API_Combo.getCombos(list.join(',')).then(res => {
        this.sortData = res
      })
    }
  }
  // eslint-disable-next-line
  multipleSelection(list=[],fl=[]) {
    // console.log(list,fl);
    // eslint-disable-next-line
    if(fl.length>0 && list.length<=0) {
      // eslint-disable-next-line
      for (let i = this.sortData.length -1 ; i >= 0; i--) {
        // eslint-disable-next-line
        let id = this.sortData[i].id;
        // eslint-disable-next-line
        if(fl.find(item => item["id"] == id)) {
          this.sortData.splice(i, 1)
        }
      }
      return false;
    }
    // eslint-disable-next-line
    let _data = [...this.sortData,...list];
    const idSet = new Set(_data.map(item => item.id));
    // eslint-disable-next-line
    const uniqueData = Array.from(idSet, id => _data.find(item => item.id == id));
    this.sortData = uniqueData;
  }

  selectionHandle(data) {
    if (data.selection.length) {
      data.selection.forEach((item, index) => {
        if (item.id === data.row.id) {
          this.sortData.push(data.row)
        } else if (index === (data.selection.length - 1)) {
          const getSelect = JSON.parse(JSON.stringify(this.sortData))
          getSelect.forEach((value, index) => {
            if (value.id === data.row.id) this.sortData.splice(index, 1)
          })
        }
      })
    } else {
      const getSelect = JSON.parse(JSON.stringify(this.sortData))
      getSelect.forEach((value, index) => {
        if (value.id === data.row.id) this.sortData.splice(index, 1)
      })
    }

    this.$emit('giftNum', this.sortData.length)
  }

  sortSave() {
    this.realGiftList = JSON.parse(JSON.stringify(this.sortData))
  }

  moveGoodsToTop(index = 0, disabled = false) {
    if (disabled) return;
    const goods = this.sortData[index];
    this.sortData.splice(index, 1)
    this.sortData.unshift(goods);
  }

  moveGoodsToPrev(index = 0, disabled = false) {
    if (disabled) return;
    const goods = this.sortData[index];
    this.sortData.splice(index, 1)
    this.sortData.splice(index - 1, 0, goods);
  }

  removeGoods(index) {
    this.sortData.splice(index, 1);
  }

  moveGoodsToNext(index = 0, disabled = false) {
    if (disabled) return;
    const goods = this.sortData[index];
    this.sortData.splice(index, 1)
    this.sortData.splice(index + 1, 0, goods);
  }

  moveGoodsToBottom(index = 0, disabled = false) {
    if (disabled) return;
    const goods = this.sortData[index];
    this.sortData.splice(index, 1)
    this.sortData.push(goods);
  }
}
