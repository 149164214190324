
// @ts-nocheck
import {Component, Mixins} from 'vue-property-decorator';
import {EditorExtra} from '../module';
import config from './$config';
import {FloorItem9Proxy} from './$proxy';
import FloorEditorFrame from '../$components/floor-editor-frame/floor-editor-frame.vue';
import ElevatorEditor from '../$components/elevator-editor/elevator-editor.vue'

  @Component({
    name: 'editor-9',
    components: {
      FloorEditorFrame,
      ElevatorEditor
    }
  })
export default class Editor extends Mixins(EditorExtra) {
    config = config;
    proxy: FloorItem9Proxy = null!;

    init(): any {
      this.proxy = new FloorItem9Proxy(this.realItem);
    }
}
